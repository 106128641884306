<template>
  <div class="rounded-10 h-auto bg-white w-full">
    <div class="mt-4">
      <div class="">
        <div class="w-full flex text-90 text-c14 font-c7 text-left mb-6 pl-6 pr-4">
          <div class="w-1/6">
            Nº
          </div>
          <div class="w-1/6 ml-4">
            Date
          </div>
          <div class="w-2/5 ml-4">
            Client
          </div>
          <div class="w-1/5 ml-4">
            Montant
          </div>
          <div class="w-1/5 ml-4">
            Statut
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div
          class="text-center text-90 mt-16"
          v-if="allDevis.length === 0"
        >
          Aucun devis enregistré
        </div>

        <div class="tableau pb-20">
          <div
            v-for="(item, index) in allDevis"
            :key="index"
            class="mt-5"
          >
            <div
              v-if="index > 0"
              class="diviser mt-5"
            />
            <div>
              <liste
                class="mt-5"
                :item="item"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../../assets/icons/arrow.svg'
import agenda from '../../../../assets/icons/agenda.svg'
import down from '../../../../assets/icons/down.svg'
import liste from './factureListe'

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
    donnes: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      charge: false,
      allDevis: []
    }
  },

  created () {
    const data = this.donnes
    this.allDevis = data.reverse()
  },

  methods: {
    // selected (item) {
    //   this.$router.push({ path: '/detailsDevis', query: { x: item.id } })
    // }
  }
}
</script>

<style lang="scss" scoped>
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
.tableau{
  max-height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
