<template>
  <div class="w-full flex items-center">
    <div
      v-for="(item, index) in option"
      :key="index"
      class="text-c14 text-90 font-c4 mr-6 cursor-pointer w-auto text-left"
      :class="{'item': position === item}"
      @click="selected(item)"
    >
      <div> {{ item }} </div>
      <div
        class="w-1/2 divis bg-principal mt-1"
        v-if="position === item"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",
  props: {
      option: {
        type: Array,
        default: null
      },
    value: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      position: null
    }
  },

  created () {
    this.position = this.value
  },

  methods: {
    selected(item){
      this.position = item
      this.$emit('info', item)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

  .item {
    color: black;
    font-weight: 800;
  }
  .divis {
    height: 2px;
    width: 50%;

  }

</style>
