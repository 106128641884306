<template>
  <div class="flex w-full text-left">
    <div
      class="w-1/3 bg-white flex h-100 bord items-center pl-6 pr-4"
      @click="goTo('clients')"
    >
      <icon
        :data="icons.client"
        height="50"
        width="50"
        class="mr-4 cursor-pointer"
        original
      />
      <div>
        <div class="text-c14 text-83">
          Clients
        </div>
        <div class="mt-1 font-c8 text-c24">
          {{ flow.allClient.toLocaleString() }}
        </div>
      </div>
    </div>

    <div
      class="w-1/3 ml-4 bg-white flex h-100 bord items-center pl-6 pr-4"
      @click="goTo('fournisseurs')"
    >
      <icon
        :data="icons.supplier"
        height="50"
        width="50"
        class="mr-4 cursor-pointer"
        original
      />
      <div>
        <div class="text-c14 text-83">
          Fournisseurs
        </div>
        <div class="mt-1 font-c8 text-c24">
          {{ flow.supplier.toLocaleString() }}
        </div>
      </div>
    </div>

    <div
      class="w-1/3 ml-4 bg-white flex h-100 bord items-center pl-6 pr-4"
      @click="goTo('collaborateurs')"
    >
      <icon
        :data="icons.collaborater"
        height="50"
        width="50"
        class="mr-4 cursor-pointer"
        original
      />
      <div>
        <div class="text-c14 text-83">
          Collaborateurs
        </div>
        <div class="mt-1 font-c8 text-c24">
          {{ flow.collaborater.toLocaleString() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import client from '../../../assets/icons/ic-client.svg'
import supplier from '../../../assets/icons/ic-supplier.svg'
import collaborater from '../../../assets/icons/ic-collaborater.svg'

export default {
  name: "Index",
props: {
  flow: {
    type: Object,
    default: null
  }
},
  data () {
    return {
      icons: {
        client,
        supplier,
        collaborater
      },
    }
  },

  methods: {
    goTo (index) {
      this.$router.push(index)
    }
  },
}
</script>

<style lang="scss" scoped>
.bord{
  border: 1.02605px solid #EDEDEE;
  border-radius: 4px;
  cursor: pointer;
}
</style>
