<template>
  <div class="w-full text-left">
    <div class="bg-yell bord flex items-center justify-center h-99 text-white">
      <icon
        :data="icons.connect"
        height="60"
        width="60"
        class="mr-2 cursor-pointer"
        original
      />
      <div class="w-2/3">
        <div class="text-c14">
          Dernière connexion
        </div>
        <div class="mt-1 font-c8 text-c22 ">
          {{ dateConverter(flow.lastConnection) }}
        </div>
      </div>
    </div>

    <div class="bg-green-second bord flex mt-4 items-center justify-center h-99 text-white">
      <icon
        :data="icons.connect"
        height="60"
        width="60"
        class="mr-2 cursor-pointer"
        original
      />
      <div class="w-2/3">
        <div class="text-c14">
          Dernière sauvegarde
        </div>
        <div class="mt-1 font-c8 text-c22 ">
          {{ dateConverter(flow.lastSave) }}
        </div>
      </div>
    </div>


    <div class="w-full text-left bg-white bord mt-6">
      <div class="text-c16 font-c7 p-6">
        Informations sur l’entreprise
      </div>
      <div class="w-full h-0.5 bg-e5" />

      <div class="p-6">
        <div class="w-fulll bg-white flex items-center mb-4">
          <div class="text-c12 text-83 w-2/5">
            Nom:
          </div>

          <div class="w-3/5 text-c14">
            {{ flow.commerce.fullName }}
          </div>
        </div>

        <div class="w-fulll bg-white flex items-center mb-4">
          <div class="text-c12 text-83 w-2/5">
            Catégorie :
          </div>

          <div class="w-3/5 text-c14">
            {{ flow.commerce.categorie }}
          </div>
        </div>

        <div class="w-fulll bg-white flex items-center mb-4">
          <div class="text-c12 text-83 w-2/5">
            Taille :
          </div>

          <div class="w-3/5 text-c14">
            {{ flow.commerce.taille }}
          </div>
        </div>

        <div class="w-fulll bg-white flex items-center mb-4">
          <div class="text-c12 text-83 w-2/5">
            Site internet :
          </div>

          <div class="w-3/5 text-c14">
            {{ flow.commerce.webSite }}
          </div>
        </div>

        <div class="w-fulll bg-white flex items-center mb-4">
          <div class="text-c12 text-83 w-2/5">
            Gérant :
          </div>

          <div class="w-3/5 text-c14">
            <span v-if="company">{{ company.body.firstName }} {{ company.body.lastName }}</span>
          </div>
        </div>

        <div class="w-fulll bg-white flex items-center mb-4">
          <div class="text-c12 text-83 w-2/5">
            Téléphone :
          </div>

          <div class="w-3/5 text-c14">
            <span v-if="company">{{ company.body.phone }}</span>
          </div>
        </div>

        <div class="w-fulll bg-white flex items-center mb-4">
          <div class="text-c12 text-83 w-2/5">
            SDF partenaires :
          </div>

          <div class="w-3/5 text-c14">
            {{ !flow.mySfd ? '- -': '' }}
            <div v-if="flow.mySfd">
              {{ flow.mySfd.length === 0 ? '- -': '' }}
              <div
                v-for="(item, index) in flow.mySfd"
                :key="index"
              >
                <div class="mb-1">
                  {{ item.body.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-fulll bg-white flex items-center mb-4">
          <div class="text-c12 text-83 w-2/5">
            Date d’inscription :
          </div>

          <div class="w-3/5 text-c14">
            {{ dateConverter(flow.commerce.createdAt) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import connect from '../../../assets/icons/ic-last-connect.svg'
import save from '../../../assets/icons/ic-last-save.svg'

export default {
  name: "Index",
props: {
  flow: {
    type: Object,
    default: null
  }
},
  data () {
    return {
      icons: {
        save,
        connect
      },
      company: null
    }
  },

  created () {
    this.company = this.$store.getters.trackCompany
    // console.log('company data')
    // console.log(this.company)
  },

  methods: {
    dateConverter (d) {
      return new Date(d).toLocaleString()
    }
  },
}
</script>

<style lang="scss" scoped>
img{
  object-fit: cover;
  width: 55px;
  height: 46px;
}
.bord {
  border-radius: 5.69577px;
}
</style>
