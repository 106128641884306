<template>
  <div class="w-full rounded-10 h-auto bg-white p-6">
    <div class="flex">
      <div class="flex w-1/2">
        <div class="flex items-center w-2/5">
          <div class="w-2 h-10 diviser1" />
          <div class="ml-4 text-left">
            <div class="text-c10 text-83">
              CHIFFRE D’AFFAIRES
            </div>
            <div class="text-c16 font-c8">
              {{ flow.turnover.toLocaleString() }} f
            </div>
          </div>
        </div>

        <div class="flex items-center w-2/5">
          <div class="w-2 h-10 diviser2" />
          <div class="ml-4 text-left">
            <div class="text-c10 text-83">
              DÉPENSES
            </div>
            <div class="text-c16 font-c8">
              {{ flow.expenses.toLocaleString() }} f
            </div>
          </div>
        </div>
      </div>

      <div class="w-1/2 flex justify-end">
        <div class="w-2/5 text-left">
          <div class="text-c10 text-83">
            RÉSULTAT NET
          </div>
          <div class="text-c16 font-c8">
            {{ (flow.turnover - flow.expenses).toLocaleString() }} f
          </div>
        </div>

        <div class="w-2/5 text-left">
          <multiselect
            :option="periode"
            value-t="Période"
            icon-height="20"
            :icon="icons.calendar"
          />
        </div>
      </div>
    </div>


    <div
      class="mt-4"
      v-if="allStat"
    >
      <apexchart
        width="700"
        type="bar"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import multiselect from '../../helper/form/multiselect'
import calendar from '../../../assets/icons/calendar.svg'

export default {
  name: "Index",
  components: {
    multiselect
  },

  props: {
    flow: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        calendar
      },
      periode: ['Année en cours'],
      allStat: null,
      series: [
        {
          name: 'DÉPENSES',
          data: [44, 55, 41, 67, 22, 43]
        },
        {
          name: 'CHIFFRE D’AFFAIRES',
          data: [13, 23, 20, 8, 13, 27]
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
          },
        },
        xaxis: {
          type: 'string',
          categories: ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUI', 'JUI', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC'],
        },
        legend: {
          show: false,
        },
        fill: {
          opacity: 1,
          colors: ['#7BD0FF', '#64C877']
        }
      },
    }
  },

  created () {
    this.getBillan()
  },

  methods: {
    getBillan () {
      const company = this.$store.getters.trackCompany
      http.post(apiroutes.baseURL + apiroutes.bilanStat, {
        id: company.id
      })
          .then(response => {
            this.allStat = response
            // console.log('bilan')
            // console.log(response)
            this.series[1].data = response.statsTurnover
            this.series[0].data = response.statsExpenses
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    }
  }
}
</script>

<style scoped>
.diviser1{
  width: 2.68px;
  height: 33.91px;
  background: #79E38D;
}

.diviser2{
  width: 2.68px;
  height: 33.91px;
  background: #7BD0FF;
}
</style>
