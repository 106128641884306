<template>
  <div class="pl-6 pr-4">
    <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/6">
        {{ item.ref }}
      </div>

      <div class="w-1/6 ml-4">
        {{ item.createdate.substr(0, 10) }}
      </div>

      <div class="w-2/5 ml-4 flex items-center">
        <icon
          :data="icons.client"
          height="20"
          width="20"
          class="mr-2 cursor-pointer"
          original
        />
        <div> {{ item.name }} </div>
      </div>

      <div class="w-1/5 ml-4 font-c7">
        {{ parseInt(item.total).toLocaleString() }} fcfa
      </div>

      <div class="w-1/5 ml-4">
        <buton
          v-if="parseInt(item.reste) > 0"
          label="En attente"
          height="30px"
          background="rgba(59, 137, 244, 0.15)"
          color="#3B89F4"
          size="12px"
          weight="500"
          radius="36px"
          class="w-4/5"
        />

        <buton
          v-if="parseInt(item.reste) === 0"
          label="Fermé"
          height="30px"
          background="rgba(64, 64, 76, 0.15)"
          color="#40404C"
          size="12px"
          weight="500"
          radius="36px"
          class="w-3/5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import client from '../../../../assets/icons/ic-clientAv.svg'
import buton from '../../../helper/add/button'

export default {
  name: "Index",
  components: {
    buton
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        client,
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
